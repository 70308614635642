import {ALL_SYMBOLS} from "./Number";
import {useEffect, useRef} from "react";

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback, ref]);
}


export const Selection = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        props.setSelecting(false)
    });
    const selections = ALL_SYMBOLS.map((symbol, index) => {
        return <button className={symbol === " " ? "blank_symbol_selector" : ""} onClick={() => props.setSymbol(symbol)} key={index}>
            <img className={""} alt="" src={"/icons/" + symbol + ".png"}/>
        </button>
    })
    return <div className="selection_container" ref={wrapperRef}>
        {selections}
    </div>;
}

import {useEffect, useState} from "react";

const DOMANDE = [
    "Problemi",
    "Dubbi",
    "Domande",
    "Questioni",
    "Interrogativi",
    "Quesiti",
]

export const Contattami = () => {
    const [email, setEmail] = useState("");
    const [index, setIndex] = useState(-1);
    useEffect(() => {
        setIndex(Math.floor(Math.random() * DOMANDE.length));
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setEmail("info@calcoloenigmatico.it");
        }, 500)
    });
    const tag = DOMANDE[index];
    return <div id="contattami">
        {index >= 0 ? (<a href={"mailto:" + email + "?subject=" + tag}>{tag}?</a>) : <></>}
    </div>

}

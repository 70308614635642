import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {CreateNumber, INITIAL_NUMBERS, ALL_SYMBOLS, NO_SYMBOL, toggleRotate} from "./Number";
import {CreateOperator, INITIAL_OPERATORS} from "./Operator";
import {Selection} from "./Selector";

const NULL_SOLUTION = {
    "solution": ALL_SYMBOLS.reduce((d, sym) => {
        if (sym !== NO_SYMBOL) {
            d[sym] = null;
        }
        return d
    }, {}),
    "solved": false
};

const EquationComponent = (props) => {
    const [solution, setSolution] = useState(NULL_SOLUTION);
    const equationRef = useRef(null);
    const isComputing = props.isComputing;
    const setIsComputing = props.setIsComputing;

    const [operators, setOperators] = useState(() => {
        const saved = localStorage.getItem("OPERATORS");
        return saved !== null ? JSON.parse(saved) : INITIAL_OPERATORS;
    });
    const [numbers, setNumbers] = useState(() => {
        const saved = localStorage.getItem("NUMBERS");
        if (saved !== null) {
            return JSON.parse(saved).map(s => ({
                ...s, rotated: [false, false, false, false]
            }));
        } else {
            return INITIAL_NUMBERS;
        }
    });
    const [rotating, setRotating] = useState(false);
    const [rotatingNumber, setRotatingNumber] = useState(0);
    const [rotatingNumberIndex, setRotatingNumberIndex] = useState(0);
    const [rotatingNumberWait, setRotatingNumberWait] = useState(false);
    const [audio, setAudio] = useState(new Audio("/sigla.mp3"));
    const computingAudio = useMemo(() => {
        return new Audio("heartbeat.wav");
    }, [])
    useEffect(() => {
        computingAudio.addEventListener('ended', function () {
            this.currentTime = 0;
            if (isComputing) {
                this.play();
            } else {
                this.pause();
            }
        }, false);
    }, [isComputing, computingAudio])

    useEffect(() => {
        localStorage.setItem("NUMBERS", JSON.stringify(numbers));
        //localStorage.removeItem("SOLUTION");
    }, [numbers]);
    useEffect(() => {
        localStorage.setItem("OPERATORS", JSON.stringify(operators));
        //localStorage.removeItem("SOLUTION");
    }, [operators]);
    useEffect(() => {
        localStorage.setItem("SOLUTION", JSON.stringify(solution));
    }, [solution])


    useEffect(() => {
        const interval = setInterval(() => {
            if (!rotating) {
                clearInterval(interval);
                audio.pause();
                return;
            }
            if (rotatingNumber >= numbers.length) {
                setRotating(false);
                setRotatingNumber(0);
                return;
            }
            if (rotatingNumberWait) {
                setRotatingNumberWait(false);
                return;
            }
            if (rotatingNumberIndex >= numbers[0].value.length) {
                setRotatingNumber(rotatingNumber + 1);
                setRotatingNumberIndex(0);
                setRotatingNumberWait(true);
                return;
            }
            toggleRotate(setNumbers, rotatingNumber, rotatingNumberIndex);
            setRotatingNumberIndex(rotatingNumberIndex + 1);
        }, solution.solved ? 300 : 50);
        return () => clearInterval(interval);
    }, [audio, numbers, solution, rotating, rotatingNumber, rotatingNumberIndex, rotatingNumberWait])

    const risolvi = useCallback(() => {
        if (rotating) {
            return;
        }
        if (!solution.solved) {
            const solverWorker = new Worker("/Solver.js");
            setIsComputing(true);
            computingAudio.play();

            solverWorker.postMessage({
                "solver": true, "numbers": numbers, "operators": operators
            });
            solverWorker.onmessage = (e) => {
                setIsComputing(false);
                if (!e.data.solved) {
                    console.log("cannot solve");
                } else {
                    setSolution({
                        "solution": e.data.result, "solved": true
                    });
                    audio.play();
                    setRotating(true);
                }
            };

        } else {
            setAudio(new Audio("/sigla.mp3"));
            setSolution({
                solved: false, solution: solution.solution
            });
            setRotating(true);
        }
    }, [operators, rotating, audio, solution, numbers, setIsComputing, computingAudio]);

    const [clickedNumber, setClickedNumber] = useState({"number": 0, "index": 0})
    const [selecting, setSelecting] = useState(false);

    const setNumber = useCallback((newSymbol) => {
        setSelecting(false);
        const newValue = numbers[clickedNumber.number].value;
        newValue[clickedNumber.index] = newSymbol;
        if (newSymbol === NO_SYMBOL) {
            if (clickedNumber.index === 3) {
                return;
            } else {
                for (let i = 0; i < clickedNumber.index; i++) {
                    newValue[i] = NO_SYMBOL;
                }
            }
        }
        setNumbers(prev => {
            return [...prev.slice(0, clickedNumber.number), {
                ...prev[clickedNumber.number], value: newValue,
            }, ...prev.slice(clickedNumber.number + 1)]
        });
    }, [numbers, clickedNumber])


    const openSelector = useCallback(() => {
        setSelecting(true);
    }, []);


    return <div id="equationWrapper">
        <div ref={equationRef} id="equation" className={(selecting ? "selecting" : "") + (" clicked_" + clickedNumber.index) + (solution.solved ? " solved" : "")}>
            <Selection setSymbol={setNumber} setSelecting={setSelecting}/>
            <table>
                <tbody>
                <tr>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={0}/>
                    </td>
                    <td><CreateOperator index={0} operators={operators} setOperators={setOperators}/></td>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={1}/>
                    </td>
                    <td><span className="equal">=</span></td>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={2}/>
                    </td>
                </tr>
                <tr>
                    <td className="snd_line_op">
                        <CreateOperator index={1} operators={operators} setOperators={setOperators}/></td>
                    <td></td>
                    <td className="snd_line_op">
                        <CreateOperator index={2} operators={operators} setOperators={setOperators}/></td>
                    <td></td>
                    <td className="snd_line_op">
                        <CreateOperator index={3} operators={operators} setOperators={setOperators}/></td>
                </tr>
                <tr>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={3}/>
                    </td>
                    <td><CreateOperator index={4} operators={operators} setOperators={setOperators}/></td>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={4}/>
                    </td>
                    <td><span className="equal">=</span></td>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={5}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={6}/>
                    </td>
                    <td><CreateOperator index={5} operators={operators} setOperators={setOperators}/></td>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={7}/>
                    </td>
                    <td><span className="equal">=</span></td>
                    <td>
                        <CreateNumber solution={solution} setClicked={setClickedNumber} openSelector={openSelector} numbers={numbers} index={8}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <button className={rotating + "_rotating " + solution.solved + "_solved"} disabled={rotating} onClick={risolvi} id="solve_button">
            <div>
                <div>
                    <p>
                        {rotating ? (solution.solved ? "🎷🎵🎺" : "...") : (solution.solved ? "Indietro" : "Risolvi!")}
                    </p>
                </div>

            </div>
        </button>
    </div>
}

export default EquationComponent;
import {useCallback} from "react";

export const ALL_OPERATORS = ["+", "-", ":", "×"]

export const INITIAL_OPERATORS = [0, 1, 2, 3, 4, 5].map((i) => {
    return {
        index: i,
        value: "+"
    }
})

export const CreateOperator = (props) => {
    const onOperatorClicked = useCallback(() => {
        const index = props.index;
        props.setOperators((prev) => {
            return [
                ...prev.slice(0, index),
                {
                    ...prev[index],
                    value: ALL_OPERATORS[(ALL_OPERATORS.indexOf(prev[index].value) + 1) % ALL_OPERATORS.length],
                },
                ...prev.slice(index + 1)
            ]
        })
    }, [props]);
    return <Operator onOperatorClicked={onOperatorClicked} id={props.index} operator={props.operators[props.index].value}/>
}
export const Operator = (props) => {
    return <div onClick={props.onOperatorClicked} className="noselect operator" id={"operator_" + props.id}>
        {props.operator}
    </div>
}

export const NO_SYMBOL = " ";
const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXY";
export const ALL_SYMBOLS = (symbols + NO_SYMBOL).split("")

export const INITIAL_NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => ({
    "value": ["A", "A", "A", "A"], "rotated": [false, false, false, false], index: i
}));

export const toggleRotate = (setNumbers, i, j) => {
    setNumbers(prev => {
        return [...prev.slice(0, i), {
            ...prev[i],
            "rotated": [...prev[i].rotated.slice(0, j), !prev[i].rotated[j], ...prev[i].rotated.slice(j + 1)]
        }, ...prev.slice(i + 1)]
    });

}
export const CreateNumber = (props) => {
    const onClick = (i) => {
        props.setClicked({
            number: props.index,
            index: i
        });
        props.openSelector();

    }
    return <Number
        rotated={props.numbers[props.index].rotated}
        symbols={props.numbers[props.index].value}
        solution={props.solution}
        onClick={onClick}
        id={props.index}
    />
}


export const Number = (props) => {
    return <div className="number" id={"number_" + props.id}>
        {[...props.symbols].map((symbol, i) => {
            const icon = <span className={"symbol_face " + (props.rotated[i] ? "rotated" : "")}>
                <img className="symbol_icon symbol_front" alt="" src={"/icons/" + symbol + ".png"}/>
                <span className="symbol_icon solution symbol_back solution_icon">{props.solution.solution[symbol]}</span>
                <div className="symbol_left symbol_side"></div>
                <div className="symbol_right symbol_side"></div>
            </span>;
            const noIcon = <span className={"symbol_face"}>
                <span className="no_symbol"></span>
            </span>;
            const isNoSymbol = symbol === NO_SYMBOL;
            const clickable = !isNoSymbol || (isNoSymbol && i < 3 && props.symbols[i + 1] !== NO_SYMBOL);
            return <span className={"symbol_wrapper_wrapper" + (clickable ? " clickable" : "")} key={i}>
                <span className={"noselect symbol_wrapper" + (isNoSymbol ? " no_symbol_wrapper" : "")}
                      onClick={clickable ? () => props.onClick(i) : () => 1}
                      key={symbol + i + props.id}>
                {symbol !== NO_SYMBOL ? icon : noIcon}
                </span>
            </span>
        })}
    </div>
}

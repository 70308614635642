import './App.css';
import Equation from "./components/EquationComponent";
import {Contattami} from "./components/Contattami";
import {useState} from "react";


function App() {
    const [isComputing, setIsComputing] = useState(false);


    return (

        <div className={isComputing ? "App loading" : "App"}>
            <header className="">Calcolo Enigmatico</header>
            <Equation setIsComputing={setIsComputing} isComputing={isComputing}/>
            <Contattami/>
        </div>
    );
}

/*
TODO:
language selector (each language has a stupid result)
 */

export default App;
